import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useLocalStorage } from "usehooks-ts"

export const useAdsTracker = () => useLocalStorage("ads-state", {})

const AdsTracker = () => {
  const location = useLocation()
  const [, setState] = useAdsTracker()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const stateUpdate = {}

    if (params.has("gclid")) {
      stateUpdate.gclid = params.get("gclid")
    }

    if (params.has("fbclid")) {
      stateUpdate.fbclid = params.get("fbclid")
    }

    setState((prevState) => ({
      ...prevState,
      ...stateUpdate,
    }))
  }, [location, setState])

  return null
}

export default AdsTracker
